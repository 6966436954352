import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useQueryArgToken } from './QueryArgTokenProvider';
import { useAuthentication } from './AuthenticationProvider';

const IdentityContext = createContext<{
    token: string | undefined;
    isLoading: boolean;
}>({
    token: undefined,
    isLoading: true,
});

export function useIdentity() {
    return useContext(IdentityContext);
}

export default function IdentityProvider({ children }: { children: ReactNode }) {
    const {
        isAuthenticated: isAuth0Authenticated,
        isLoading: isAuth0Loading,
        getAccessTokenSilently,
        getIdTokenClaims,
        loginWithRedirect,
    } = useAuth0();

    const { authConfig } = useAuthentication();

    const { token: queryArgToken, isLoading: isQueryArgTokenLoading, storeLocalToken } = useQueryArgToken();

    const [token, setToken] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (!authConfig) return;

        if (import.meta.env.VITE_INTEGRATION_ID) {
            setToken(
                'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuaWNrbmFtZSI6InByb2d5bWVkaWEifQ.w57eMQj4pW8RTGyhjLj70UaB9LD0Jj5FLDcMyv6uKVY',
            );
        } else if (authConfig?.type === 'auth0') {
            if (!isAuth0Loading && isAuth0Authenticated) {
                (async () => {
                    await getAccessTokenSilently({});

                    const tokenClaim = await getIdTokenClaims();

                    if (tokenClaim) setToken(tokenClaim.__raw);
                })().catch((error) => {
                    console.log(error);
                });
            } else {
                if (!isAuth0Loading && !isAuth0Authenticated) loginWithRedirect();
            }
        } else if (authConfig?.type === 'query-token') {
            if (!isQueryArgTokenLoading) {
                if (queryArgToken) {
                    (async () => {
                        const response = await fetch(`/api/v1/validateToken`, {
                            method: 'GET',
                            headers: {
                                Authorization: 'Bearer ' + queryArgToken!,
                            },
                            cache: 'no-store',
                        });
                        if (response.status === 200) {
                            setToken(queryArgToken);
                            storeLocalToken(queryArgToken);
                        } else if (authConfig.redirectUrl) {
                            window.location.href = authConfig.redirectUrl;
                        }
                    })().catch((error) => {
                        console.log(error);
                    });
                } else if (authConfig.redirectUrl) window.location.href = authConfig.redirectUrl;
                else throw new Error('No token found in query string and no redirect url provided in auth config');
            }
        }
    }, [
        isAuth0Loading,
        isAuth0Authenticated,
        getAccessTokenSilently,
        getIdTokenClaims,
        isQueryArgTokenLoading,
        queryArgToken,
        authConfig,
    ]);

    return (
        <IdentityContext.Provider
            value={{
                token,
                isLoading: !import.meta.env.VITE_INTEGRATION_ID && (isAuth0Loading || isQueryArgTokenLoading),
            }}
        >
            {children}
        </IdentityContext.Provider>
    );
}
