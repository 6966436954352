import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { useQueryArg } from '../components/hooks/useQueryArg';

const QueryArgTokenContext = createContext<{
    token: string | undefined;
    isLoading: boolean;
    storeLocalToken: (token: string) => void;
}>({
    token: undefined,
    isLoading: true,
    storeLocalToken: () => {},
});

export function useQueryArgToken() {
    return useContext(QueryArgTokenContext);
}

export type LocalTokenWithExpirationType = {
    token: string;
    expiration: number;
};

export default function QueryArgTokenProvider({ children }: { children: ReactNode }) {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [queryToken, setQueryToken, isQueryTokenLoading] = useQueryArg('token');

    const [localToken, setLocalToken] = useLocalStorage<LocalTokenWithExpirationType | undefined>('token', undefined);
    const [activeToken, setActiveToken] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (!isLoading) return;

        if (localToken && localToken.expiration > Date.now()) {
            setActiveToken(localToken.token);
            setIsLoading(false);
        } else if (queryToken) {
            setActiveToken(queryToken);
            setIsLoading(false);
        } else if (!isQueryTokenLoading) {
            setIsLoading(false);
        }
    }, [queryToken, localToken]);

    const storeLocalToken = useCallback((token: string) => {
        const expiration = Date.now() + 1000 * 60 * 60 * 24 * 7;

        setLocalToken({ token, expiration });

        setQueryToken(undefined);
    }, []);

    return (
        <QueryArgTokenContext.Provider
            value={{
                token: activeToken,
                isLoading,
                storeLocalToken,
            }}
        >
            {children}
        </QueryArgTokenContext.Provider>
    );
}
